import * as React from 'react';
import { graphql } from 'gatsby';
import FancyTitle from '../components/fancy-title';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';

function LegalPage({ data }) {
  const legal = data.markdownRemark;

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: legal?.frontmatter?.title, fullWidth: true });
        return (
          <div className="px-4 lg:px-16 flex flex-col py-16 pt-20 lg:pt-36 lg:pb-40 prose text-background dark:text-white max-w-none break-words">
            {legal
                && (
                  <>
                    <Seo
                      title={legal.frontmatter.title}
                      description={legal.frontmatter?.meta_description}
                    />
                    <FancyTitle text={legal.frontmatter.title} tag="h1" />
                    <div className="lg:text-xl" dangerouslySetInnerHTML={{ __html: legal.html }} />
                  </>
                )}
          </div>
        );
      }}
    </LayoutConsumer>
  );
}

export default LegalPage;

export const legalQuery = graphql`
  query LegalBySlug(
    $id: String!
  ) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        meta_description
      }
    },
  }
`;
